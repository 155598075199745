import React from 'react';

const PrivacyPolicy = () => {
  const containerStyle = {
    padding: '40px', // Increased padding for more space around the edges
    maxWidth: '1000px', // Set max width for better readability
    margin: '0 auto', // Center the content
  };

  const titleStyle = {
    fontSize: '32px', // Reduced font size
    fontWeight: 'bold',
    marginBottom: '20px',
  };

  const subtitleStyle = {
    fontSize: '24px', // Reduced font size
    fontWeight: 'bold',
    marginBottom: '15px',
  };

  const textStyle = {
    fontSize: '16px', // Reduced font size
    lineHeight: '1.6',
    marginBottom: '20px',
    fontWeight: '500', // Increased font weight
  };

  const listStyle = {
    marginLeft: '30px', // Increased margin for lists
    marginBottom: '20px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Villa Sherover Privacy Policy</h1>
      <h2 style={subtitleStyle}>Summary</h2>
      <p style={textStyle}>
        Villa Sherover is committed to keeping your private data private. Thus, we only collect your personal data as we need it to provide our products and services, and for the fulfillment of our contracts and record-keeping requirements. The Villa Sherover Privacy Policy details the types of information we collect from you, how we collect the data, how we use it, and how you can control your personal data.
      </p>
      <p style={textStyle}>
        What do we mean by personal data? Well, some examples of data we collect include your name, your address information, and password… really any data that could be used to identify you.
      </p>
      <p style={textStyle}>
        There’s a lot of information provided in this document, so we understand if it’s a lot to take in. If you have questions, please contact our privacy DPO by at <a href="mailto:brakagary@gmail.com">brakagary@gmail.com</a>. We actively monitor this inbox.
      </p>
      <h2 style={subtitleStyle}>When we collect your private data</h2>
      <h3 style={subtitleStyle}>Data you provide</h3>
      <p style={textStyle}>
        We typically collect private data collected from you when you voluntarily provide it to us, for instance, when you:
      </p>
      <ul style={listStyle}>
        <li style={textStyle}>create an account. For example: email address, phone number, mailing address, fax number, username, and password.</li>
        <li style={textStyle}>purchase products and services. For example: credit card number and PayPal account.</li>
        <li style={textStyle}>contact us. For example: emailing us, submitting a contact form, submitting a support request, or calling us on the telephone.</li>
        <li style={textStyle}>sign up for a newsletter (email or postal address).</li>
      </ul>
      <h3 style={subtitleStyle}>Other data associated with you</h3>
      <p style={textStyle}>
        We also collect and store personal information in less-obvious ways in order to provide our products and services. These include:
      </p>
      <ul style={listStyle}>
        <li style={textStyle}>usernames, account numbers, purchase history, coupon usage history, login history, default contacts.</li>
        <li style={textStyle}>logged data and metadata that includes date and time information about when you accessed Villa Sherover or any of its products and services.</li>
      </ul>
      <h3 style={subtitleStyle}>Data offered by your web browser</h3>
      <p style={textStyle}>
        We also utilize information freely offered by your web browser such as:
      </p>
      <ul style={listStyle}>
        <li style={textStyle}>your browser name and browser version.</li>
        <li style={textStyle}>your device type, make, and model.</li>
        <li style={textStyle}>your operating system.</li>
        <li style={textStyle}>browser settings and capabilities.</li>
        <li style={textStyle}>the presence of plugins.</li>
        <li style={textStyle}>your display size, color depth, and resolution.</li>
        <li style={textStyle}>your specified language preference.</li>
      </ul>
      <h3 style={subtitleStyle}>Cookies</h3>
      <p style={textStyle}>
        Like many websites, we use "cookies." Cookies are alphanumeric identifiers that Villa Sherover and its partners transfer to your computer's hard drive through your web browser to enable our respective systems to recognize your browser. We and our partners use persistent, identifying cookies to remember your information and to link your activities to you as well as temporary cookies. While you can take steps to turn off, block or disable cookies, if you do, Villa Sherover may not function and appear as we have designed it. Nonetheless, if you want to take these steps, you can do so by following the instructions associated with your browser.
        Our cookies and the cookies collected and stored by our trusted partners are used for purposes that include:
      </p>
      <ul style={listStyle}>
        <li style={textStyle}>to track sessions, and browsing behavior including links and buttons clicked.</li>
        <li style={textStyle}>to detect and prevent fraud and account intrusion.</li>
        <li style={textStyle}>to detect unique devices.</li>
        <li style={textStyle}>to improve site experience.</li>
        <li style={textStyle}>to prevent fraud.</li>
        <li style={textStyle}>to collect analytics data and other data about how you use our products and services.</li>
      </ul>
      <h3 style={subtitleStyle}>Your IP address</h3>
      <p style={textStyle}>
        The IP address of your computer is recorded and used for several purposes including:
      </p>
      <ul style={listStyle}>
        <li style={textStyle}>to detect and prevent fraud and account intrusion.</li>
        <li style={textStyle}>to customize your experience on the site.</li>
        <li style={textStyle}>to approximate your location.</li>
      </ul>

      <h2 style={subtitleStyle}>Supplemental data</h2>
      <p style={textStyle}>
      Supplemental data may be received about you from data taken from public sources and other sources such as companies we have paid, and other trusted partners, using data we have already collected about you. We use this data to detect fraud, detect account intrusion, and to better deliver products and services.      </p>
      <h2 style={subtitleStyle}>What we do with your collected data</h2>
      <p style={textStyle}>
        Villa Sherover seeks to reduce the amount of data we collect, and tries to limit its use to situations where we have been granted permission to collect the data.
      </p>
      <p style={textStyle}>
        We pledge to use this data solely in order to provide, improve, and optimize our products and services, to fulfill contracts, record-keeping, or regulatory obligations, or as required by a law enforcement agency, lawful order, or as required or permitted for other lawful purposes.
      </p>
      
      <h2 style={subtitleStyle}>Providing and improving products and services</h2>
      <p style={textStyle}>
        We use your data for the following purposes:
      </p>
      <ul style={listStyle}>
        <li style={textStyle}>Normal operation of our products and services</li>
        <li style={textStyle}>Handling contact form submissions and account creation</li>
        <li style={textStyle}>Detection of fraud, abuse, and intrusion</li>
        <li style={textStyle}>Investigation of complaints about fraud or illegal activity</li>
        <li style={textStyle}>Improving our products and services, including analysis of user behaviors</li>
        <li style={textStyle}>Collection of aggregate data and statistics about use of our products and services</li>
        <li style={textStyle}>Verifying your identity</li>
        <li style={textStyle}>Communicating with you</li>
      </ul>

      <h2 style={subtitleStyle}>Sharing with trusted third parties</h2>
      <p style={textStyle}>
        We may share your personal data with trusted third parties and contractors to provide our products and services, including:
      </p>
      <ul style={listStyle}>
        <li style={textStyle}>Payment processors such as PayPal or Stripe</li>
        <li style={textStyle}>Fraud, abuse, and intrusion prevention services</li>
        <li style={textStyle}>Companies that serve advertisements</li>
        <li style={textStyle}>Companies that we contract to contact you on our behalf</li>
        <li style={textStyle}>Customer relationship management services</li>
        <li style={textStyle}>Surveys and contest services</li>
      </ul>
      <p style={textStyle}>
        If we collect information from you in connection with a co-branded offer, we'll make it clear which partner's privacy policy applies. If you believe your personal information has been submitted without your permission, please contact brakagary@gmail.com.
      </p>
      <p style={textStyle}>
        If you connect to us from outside the country in which our servers, a partner's servers, or a member of our staff are located, your data will pass across international borders.
      </p>

      <h2 style={subtitleStyle}>Law enforcement</h2>
      <p style={textStyle}>
        We will reveal your personal information to law enforcement, governmental authorities, and private parties as required by law. We may additionally disclose your personal data to comply with legal processes, protect individual safety, or comply with requests from 3rd parties with legitimate interests. When possible, we'll try to inform you if we've revealed your personal data in one of these scenarios.
      </p>
      <p style={textStyle}>
        If you believe you have a legitimate interest and right to access personal data currently being redacted by Villa Sherover, please contact brakagary@gmail.com.
      </p>

      <h2 style={subtitleStyle}>Website analytics</h2>
      <p style={textStyle}>
        Your activity on our site may be tracked by analytics services such as Google Analytics. These services may store cookies on your computer and use them to identify you. This data may be transmitted across international borders and may include information that could be used to personally identify you.
      </p>

      <h2 style={subtitleStyle}>Targeted advertisements</h2>
      <p style={textStyle}>
        Villa Sherover may advertise or work with trusted third parties to advertise our products on other websites in a targeted fashion. These ads may be served to you based on tracked interactions with Villa Sherover or partner websites.
      </p>
      <p style={textStyle}>
        We may use automated decision-making processes to decide how to market to you based on your account activity.
      </p>

      <h2 style={subtitleStyle}>Where your data is stored</h2>
      <p style={textStyle}>
        Personal data collected by Villa Sherover is stored on servers operated by Villa Sherover and our trusted partners.
      </p>

      <h2 style={subtitleStyle}>How we secure your data</h2>
      <p style={textStyle}>
        Villa Sherover uses industry-standard practices for safeguarding your private data, including encryption. We utilize physical, electronic, and managerial procedures to help safeguard your information, although we do not guarantee its security. We ensure our partners follow best practices for safeguarding your data as well.
      </p>

      <h2 style={subtitleStyle}>How long we retain your data</h2>
      <p style={textStyle}>
        We generally retain your personal data only as long as required to provide our products and services. However, we may retain data longer due to legal or contractual requirements, including regulatory contracts, and for financial accounting purposes.
      </p>

      <h2 style={subtitleStyle}>How to request we delete your personal data</h2>
      <p style={textStyle}>
        To request deletion of your personal data, email your request to brakagary@gmail.com. We will delete everything other than what's required for contractual or record-keeping purposes. We will respond to data removal requests within 30 days.
      </p>

      <h2 style={subtitleStyle}>Links</h2>
      <p style={textStyle}>
        Villa Sherover's website or communications may provide links to other sites. We are not responsible for the privacy practices of these links. We encourage you to read the privacy statements of every website that collects your personally identifiable information.
      </p>

      <h2 style={subtitleStyle}>Age Restrictions</h2>
      <p style={textStyle}>
        Villa Sherover is intended for customers 18 years or older. If you know of anyone under 18 who has provided us with personal data, please contact us at brakagary@gmail.com.
      </p>

      <h2 style={subtitleStyle}>Contact</h2>
      <p style={textStyle}>
        If you have questions about our Privacy Policy, our products and services, or to report a privacy violation, email us at brakagary@gmail.com. We will respond to all requests and inquiries within 30 days.
      </p>

    </div>
  );
};

export default PrivacyPolicy;
