import React, { useState, useRef, useCallback } from "react";
import emailjs from "@emailjs/browser";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import logosherover from "assets/img/logosherover.png";
import villacam01 from "assets/img/villacamoverlay.png";
import MenuItem from "@mui/material/MenuItem";
import { PriorityCountrySelect } from "components/Button/CountrySelect";

function SectionContactUs() {
  const form = useRef();
  const time = [
    { code: 'Morning', label: 'Morning' },
    { code: 'Afternoon', label: 'Afternoon' },
    { code: 'Evening', label: 'Evening' },
  ]
  const contact = [
    { code: 'Email', label: 'Email' },
    { code: 'Whatsapp', label: 'Whatsapp' },
  ]

  const [formValues, setFormValues] = useState({
    from_name: "",
    m_telephone: "",
    p_email: "",
    country: "",
    preferred_time: "",
    preferred_contact: "",
  });
  const [formErrors, setFormErrors] = useState({
    from_name: false,
    m_telephone: false,
    p_email: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleCountryChange = useCallback((e) => {
    handleInputChange(e);
  }, []);

  const validateForm = () => {
    const errors = {
      from_name: !formValues.from_name,
      m_telephone: !formValues.m_telephone,
      p_email: !formValues.p_email,
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error === true);
  };

  

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsLoading(true);
    emailjs
      .sendForm(
        "service_fncsr28",
        "template_va1z5zt",
        form.current,
        "4_nHas6EPANAUCvJy"
      )
      .then(
        () => {
          setIsLoading(false);
          setIsSubmitted(true);
        },
        (error) => {
          setIsLoading(false);
          console.error("FAILED...", error.text);
        }
      );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div
        id='contact_section'
        style={{
          backgroundImage: `url(${villacam01})`,
          backgroundSize: "cover",
          width: "100%",
          position: "relative",
        }}
      >
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 0 }} sx={{ py: 6 }}>
          <Grid 
            item 
            xs={12} 
            md={12} 
            className="image-containerC"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
            }}
          >
            <img 
              src={logosherover} 
              style={{ 
                width: "15rem",
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }} 
              alt="Logo" 
            />
          </Grid>
          <Grid item xs={12} md={12} align="center">
            <p
              style={{
                width: "100%",
                color: "white",
                fontSize: "22px",
                marginTop: "2rem",
              }}
            >
              Fill the form or call{" "}
              <a
                href="tel:02-5670303"
                style={{
                  color: "white",
                  textDecoration: "none",
                  transition: "color 0.3s ease",
                }}
                onMouseEnter={(e) => e.target.style.color = "#f0f0f0"}
                onMouseLeave={(e) => e.target.style.color = "white"}
              >
                02-5670303
              </a>
            </p>
          </Grid>
        </Grid>

        <form
          ref={form}
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gap: '20px',
            padding: '20px',
            maxWidth: '800px',
            margin: '0 auto',
          }}
          noValidate
          autoComplete="off"
          onSubmit={sendEmail}
        >
          <TextField
            required
            label="Name"
            name="from_name"
            id="from_name"
            size="small"
            variant="standard"
            value={formValues.from_name}
            onChange={handleInputChange}
            error={formErrors.from_name}
            helperText={formErrors.from_name ? "Name is required" : ""}
            fullWidth
            style={{ gridColumn: 'span 6', marginBottom: '20px' }}
          />
          <TextField
            required
            label="Telephone"
            name="m_telephone"
            id="m_telephone"
            size="small"
            variant="standard"
            value={formValues.m_telephone}
            onChange={handleInputChange}
            error={formErrors.m_telephone}
            helperText={formErrors.m_telephone ? "Telephone is required" : ""}
            fullWidth
            style={{ gridColumn: 'span 6', marginBottom: '20px' }}
          />
          <TextField
            required
            label="Email"
            name="p_email"
            id="p_email"
            size="small"
            variant="standard"
            value={formValues.p_email}
            onChange={handleInputChange}
            error={formErrors.p_email}
            helperText={formErrors.p_email ? "Email is required" : ""}
            fullWidth
            style={{ gridColumn: 'span 6', marginBottom: '20px' }}
          />
          <div style={{ gridColumn: 'span 6', marginBottom: '20px' }}>
            <PriorityCountrySelect
              value={formValues.country}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
          <TextField
            select
            label="Preferred Time"
            variant="standard"
            name="preferred_time"
            id="preferred_time"
            size="small"
            fullWidth
            value={formValues.preferred_time}
            onChange={handleInputChange}
            style={{ gridColumn: 'span 6', marginBottom: '20px' }}
          >
            {time.map((option) => (
              <MenuItem key={option.code} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Preferred Contact"
            name="preferred_contact"
            id="preferred_contact"
            variant="standard"
            size="small"
            fullWidth
            value={formValues.preferred_contact}
            onChange={handleInputChange}
            style={{ gridColumn: 'span 6', marginBottom: '20px' }}
          >
            {contact.map((option) => (
              <MenuItem key={option.code} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {/* Updated consent text row */}
          <p style={{ 
            gridColumn: 'span 12',
            '@media (min-width: 600px)': {
              gridColumn: 'span 6',
            },
            color: "white", 
            fontSize: '0.8rem', 
            textAlign: 'left',
            marginTop: '1rem',
            fontWeight: '500'
          }}>
            By submitting the form you consent to our privacy policy and to the use of cookies
          </p>

          <div style={{
            gridColumn: 'span 12',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingBottom: '2rem',
            marginTop: '20px'
          }}>
            <Button
              variant="outlined"
              type="submit"
              disabled={isSubmitted || isLoading}
            >
              {isLoading ? "Sending..." : "Submit"}
            </Button>
            {isSubmitted && (
              <p style={{ color: "green", marginTop: '0.5rem', fontWeight: "bold" }}>
                Thank you! Your message has been sent.
              </p>
            )}
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
}

export default SectionContactUs;