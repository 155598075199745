/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row className="align-items-center">
          <nav className="footer-nav">
            <ul>
              <li>
                <a
                  href="https://www.elisium.so"
                  target="_blank"
                >
                  Elisium
                </a>
              </li>
            </ul>
          </nav>
          <div className="text-center" style={{ flex: 1 }}>
            <span style={{ fontSize: "0.8em" }}>
              All information and pictures may be changed and are not binding.
              {" "}
              <span style={{ marginLeft: "15px" }}>
                <a href="/privacy-policy" onClick={(e) => { e.preventDefault(); window.location.href = '/privacy-policy'; }} style={{ color: 'inherit', cursor: 'pointer' }}>Privacy Policy</a>
                {" "}
                <a href="/accessibility-statement" style={{ color: 'inherit', marginLeft: "10px" }}>Accessibility Statement</a>
              </span>
            </span>
          </div>
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, made 
              by Elisium{" "}
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
