import React, { useState, useEffect } from "react";
import classnames from "classnames";
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    const offset = -80;
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });

    setActiveSection(sectionId);
  };

  useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
        setNavbarColor("");
      } else {
        setNavbarColor("navbar-transparent");
      }
    };

    const handleScroll = () => {
      updateNavbarColor();

      const sections = ["history_section", "gallery_section", "property_section", "documentation_section", "neighborhood_section"];
      let currentSection = "";

      for (let section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 100 && rect.bottom >= 100) {
            currentSection = section;
            break;
          }
        }
      }

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = [
    { id: "history_section", text: "History" },
    { id: "gallery_section", text: "Gallery" },
    { id: "property_section", text: "Property" },
    { id: "documentation_section", text: "Documentation" },
    { id: "neighborhood_section", text: "Neighborhood" },
  ];

  const getTextColor = (isActive) => {
    if (navbarColor === "navbar-transparent") {
      return isActive ? "#956B35" : "white";
    } else {
      return isActive ? "#956B35" : "#A9A9A9";
    }
  };

  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand 
            data-placement="bottom" 
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            style={{ 
              fontSize: '1rem', 
              fontWeight: 'bold',
              color: navbarColor === "navbar-transparent" ? "white" : "inherit",
              cursor: 'pointer'
            }}
          >
            Villa Sherover
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
          <Nav navbar>
            {navItems.map((item) => (
              <NavItem key={item.id}>
                <NavLink
                  href={`#${item.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection(item.id);
                  }}
                  style={{
                    color: getTextColor(activeSection === item.id),
                    transition: "color 0.3s ease",
                    fontSize: '1rem',
                    fontWeight: 600,
                    padding: '0.5rem 1rem',
                  }}
                >
                  {item.text}
                </NavLink>
              </NavItem>
            ))}
            <NavItem>
              <Button
                className="btn-round"
                color="#956b35"
                href="#contact_section"
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection("contact_section");
                }}
                style={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  padding: '0.5rem 1.5rem',
                }}
              >
                <i></i> CONTACT
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;