/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import logo from "assets/img/oneofakind.svg";
import V2Background from "assets/img/V2.png";

// reactstrap components
import { Container } from "reactstrap";
import hero from 'assets/img/hero.jpg';
import logosherover from "assets/img/logonew.png";
import villaHero from 'assets/img/Villa-cam-03.jpeg'
import overlay from 'assets/img/hero_overlay_one.svg'
import img1 from "assets/img/V-cam-01 - Jour.png"

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundImage: `url(${V2Background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div
          className="overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${overlay})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.7, // Adjust this value to change the overlay intensity
          }}
        />
        <div className="content-center">
         
          <Container>
            <div className="title-brand">
            <img className="logo-img" src={logosherover} alt="" style={{ marginBottom: '200px' }} />
            

              
            </div>
            <h2 className="presentation-subtitle text-center">
            </h2>
          </Container>

          <style jsx>{`
  .logo-img {
    width: 24rem;
  }

  @media (max-width: 768px) {
    .logo-img {
      width: 20rem; 
    }
  }

  @media (max-width: 480px) {
    .logo-img {
      width: 16rem; 
    }
  }
`}</style>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")",
          }}
        />
        
      </div>

      <style jsx>{`
        .page-header {
          height: 100vh;
        }

        @media (max-width: 480px) {
          .page-header {
            height: 70vh;
            min-height: 70vh;
                        background-position: 70% center;

          }
        }

      
      `}</style>
    </>
  );
}

export default IndexHeader;
