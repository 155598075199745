import React, { act, useState, useRef, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import longtrack from 'assets/img/longfoottrack.png';
import midtrack from 'assets/img/shortfoottrack.png';
import shorttrack from 'assets/img/shortyfoottrack.png';
import FootprintIcon from 'assets/img/footprint.svg';
import FootprintIconPng from 'assets/img/footprint.png';


function calculateAngle(svgPath, lengthAtPoint) {
  const point1 = svgPath.getPointAtLength(lengthAtPoint - 10);
  const point2 = svgPath.getPointAtLength(lengthAtPoint + 10);
  const dx = point2.x - point1.x;
  const dy = point2.y - point1.y;
  return Math.atan2(dy, dx) * (180 / Math.PI);
}

const steps = [
  {
    id: 1,
    lengthAtPoint: 20,
    text: "1956. Birth of a Monument",
    year: 1956,  // Added year field
    details: {
      en: "Villa Sherover, constructed between 1954 and 1956, stands as an architectural marvel in Jerusalem. " +
          "Designed by Venezuelan architects Carlos Guinand, Moses Ben Ashraf, and Emilio Vestuti, the villa showcases" +
          " a South American-inspired style. One of its most striking features is the colorful mosaics adorning the " +
          "inverted pyramid-shaped roof, adding a unique touch to its modern design. Commissioned by philanthropist " +
          "Miles Sherover for himself and his wife Gitta, the villa reflects their refined taste in art and culture." +
          " This residence symbolizes a blend of luxury and modern aesthetics in Jerusalem’s evolving architectural landscape",
      he: "וילה שרובר, שנבנתה בין השנים " +
          "1954 ל-1956, נחשבת לפלא אדריכלי בירושלים וכבר אז היה ברור שמדובר במונומנט ייחודי בנוף המקומי. היא תוכננה על ידי" +
          " האדריכלים הוונצואלים קרלוס גואיננד, משה בן אשרף ואמיליו וסטוטי, ומשקפת סגנון בהשראת אדריכלות דרום אמריקאית." +
          " אחד האלמנטים הבולטים שלה הוא הפסיפסים הצבעוניים המעטרים את הגג בצורה של פירמידה הפוכה, מה שמוסיף ייחודיות " +
          "לעיצוב המודרני. הווילה הוזמנה על ידי הפילנתרופ מיילס שרובר עבורו ועבור אשתו גיטה, ומשקפת את טעמם המובהק" +
          " באמנות ותרבות. מבנה זה סימל מאז ועד היום שילוב של יוקרה ואסתטיקה מודרנית בנוף האדריכלי המשתנה של ירושלים." 
    }
  },
  {
    id: 2,
    lengthAtPoint: 200,
    text: "1971 - Epicenter of the Elite",
    year: 1971,  // Added year field
    details: {
      en: "In 1971, the construction of the Sherover Theatre across from Villa Sherover added a new cultural dimension to Jerusalem's social scene. Designed to host grand performances, the theater became a key venue for the Sherover family’s legendary social gatherings throughout the 1970s. These events were often extensions of the villa’s already significant role, as it had served as an important meeting place for political and cultural figures since the 1960s. The villa itself was adorned with stunning mosaic work by the artist Cornelius Zitman, which ran along its length, adding a touch of artistic grandeur. The interior design was meticulously crafted by Dora Gad, a renowned architect who would later win the Israel Prize for Architecture for her work on the Israel Museum, alongside Al Mansfeld. Golda Meir, Yitzhak Rabin, Moshe Sharett, and Menachem Begin, along with international dignitaries, were frequent guests at these gatherings. In a symbolic gesture, the final turquoise stone in the villa's internal mosaic was laid by Moshe Dayan, further emphasizing the villa’s role as a hub of elite social life. Miles and Gitta Sherover, known for their philanthropy and patronage of the arts, transformed both the villa and the theater into cultural landmarks, enriching the landscape of the city.",
      he: "עוד טקסט מפורט בעברית" 
    }
  },
  {
    id: 3,
    lengthAtPoint: 450,
    text: "1976 - Worthy of a State Residence",
    year: 1976,  // Added year field
    details: {
      en: "In 1976, Villa Sherover was set to become the property of the State of Israel, intended to serve as an official guesthouse similar to Blair House in Washington. However, it was the State of Israel that ultimately decided not to make the villa public. This decision allowed the property to remain in private hands until nowadays",
      he: "עוד ועוד פרטים בעברית" 
    }
  },
  {
    id: 4,
    lengthAtPoint: 650,
    text: "1994 - Where History Takes Shape",
    year: 1994,  // Added year field
    details: {
      en: "In 1994, the historic peace agreement between Israel and Jordan was signed, officially ending the state of conflict between the two nations. As part of the negotiations leading to this agreement, several key meetings took place, one of which was held at Villa Sherover in Jerusalem. Villa Sherover, already renowned as a meeting place for public figures and leaders from Israel and abroad, provided the backdrop for one of the most significant moments in Israeli history, as the diplomatic discussions there helped advance peace in the region. The agreement, signed in October 1994, marked an important step toward stability and coexistence between the countries, with Villa Sherover quietly witnessing these events.",
      he: "מקווה לג'קוזי בעברית" 
    }
  },
  {
    id: 5,
    lengthAtPoint: 800,
    text: "2016 - A Modern Stage for Timeless Culture",
    year: 2016,  // Added year field
    details: {
      en: "In 2016, Jerusalem Design Week brought a wave of creativity and innovation, with Villa Sherover chosen as one of the central venues for the event. As one of the city's most iconic buildings, the villa provided a unique platform for artists, designers, and creators from around the world to express their work. The selection of Villa Sherover highlighted its cultural importance and central role in Jerusalem's artistic and cultural landscape. During Design Week, the villa became an integral part of the city's art dialogue, symbolizing the fusion of Jerusalem's rich history with contemporary innovation.",
      he: "פיתוח מרחב תרבותי בעברית" 
    }
  },
  {
    id: 6,
    lengthAtPoint: 950,
    text: "2019 - The Final Touch of Perfection",
    year: 2019,  // Added year field
    details: {
      en: "Before the creation of the State of Israel, the neighborhood of Talbiyeh was largely owned by the Greek Orthodox Church, which held significant tracts of land in the area. The Jewish National Fund (KKL) leased much of this land from the church, enabling development and residential use under long-term agreements. In this context, many properties, including those surrounding Villa Sherover, were tied to these leases. However, Villa Sherover stands out as one of the rare properties to have obtained full ownership of its land. In 2019, a key transaction solidified this unique status, distinguishing the villa from many other properties that remain under lease agreements with the church. This full ownership enhances its value and provides unmatched security for its owners.",
      he: "דיונים על שיפוצים אחרונים בעברית" 
    }
  }
];


function SectionHistory() {
  const [showTooltip, setShowTooltip] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [angles, setAngles] = useState([]);
  const pathRef = useRef(null);

  const [strokeDashArray, setStrokeDashArray] = useState("");


  useEffect(() => {
    if (pathRef.current) {
      const totalLength = pathRef.current.getTotalLength();
      const dotSize = 2; // Diameter of the dots
      const gapSize = 2; // Gap size between the dots

      // Calculate gaps based on elements' positions
      const gaps = steps.map(step => {
        return {
          start: step.lengthAtPoint - 15,
          end: step.lengthAtPoint + 15,
        };
      });

      // Create strokeDasharray for dotted line
      let dashArray = [];
      let currentPosition = 0;

      gaps.forEach(gap => {
        // Add dots and gaps up to the start of the current gap
        while (currentPosition < gap.start) {
          dashArray.push(dotSize, gapSize);
          currentPosition += dotSize + gapSize;
        }

        // Add the gap
        const gapLength = gap.end - currentPosition;
        dashArray.push(0, gapLength);
        currentPosition = gap.end;
      });

      // Add remaining dots and gaps to the end of the path
      while (currentPosition < totalLength) {
        dashArray.push(dotSize, gapSize);
        currentPosition += dotSize + gapSize;
      }

      // Convert dashArray to strokeDasharray format
      const dashArrayString = dashArray.join(",");
      
      // Apply to path
      pathRef.current.style.strokeDasharray = dashArrayString;
    }
  }, [pathRef.current]);
  

  useEffect(() => {
    if (pathRef.current) {
      const newAngles = steps.map(step => {
        const angle = calculateAngle(pathRef.current, step.lengthAtPoint);
        return { id: step.id, angle };
      });
      setAngles(newAngles);
    }
  }, [pathRef.current]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [activeStep, setActiveStep] = useState(0);
  // const [hoveredStep, setHoveredStep] = useState(null);

  // const handleMouseEnter = (index) => {
  //   if (index === activeStep) {
  //     setHoveredStep(index);
  //   }
  // };

  // const handleMouseLeave = () => {
  //   setHoveredStep(null);
  // };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
    setShowTooltip(!showTooltip);
    handleTooltipOpen()
  };

  const handleClick = (id) => {
    setCurrentStep(id);
  };

  //  #DCD4CD

  const [hoveredStep, setHoveredStep] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredStep(id);
  };

  const handleMouseLeave = () => {
    setHoveredStep(null);
  };

  return (
    
    <div id='history_section' className="test history-section">
   <div className="title-container">
        <h2 className="responsive-heading-h2">The</h2>
        <h1 className="responsive-heading-h1">Tales of Villa Sherover</h1>
      </div>


<svg
        width="100%"
        height="400px"
        viewBox="0 0 800 310"
        xmlns="http://www.w3.org/2000/svg"
        className="mobileTest2"
        style={{ 
          '@media (min-width: 481px)': {
            marginTop: '4rem',
            marginBottom: '-4rem'
          }
        }}
      >
        <path 
          ref={pathRef} 
          d="M 50 130 Q 172 -102 291 111 T 517 138 C 597 1 642.3333 43.3333 754 104" 
          fill="none" 
          stroke="#DCD4CD" 
          strokeWidth="2"
          strokeDasharray={strokeDashArray}
        />
        {angles.map(({ id, angle }) => {
            const step = steps.find(s => s.id === id);
            const point = pathRef.current.getPointAtLength(step.lengthAtPoint);
            const isCurrent = id === currentStep;
            const isHovered = id === hoveredStep;

          return (
            <g
              transform={`translate(${point.x}, ${point.y}) rotate(${angle + 90})`}
              onClick={() => handleClick(id)}
              onMouseEnter={() => handleMouseEnter(id)}
              onMouseLeave={handleMouseLeave}
              style={{
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                transform: isHovered ? `translate(${point.x}, ${point.y}) rotate(${angle + 90}) scale(1.1)` : `translate(${point.x}, ${point.y}) rotate(${angle + 90})`,
              }}
            >
              <image
                className={`footprint-icon imageMobile2 ${isCurrent ? 'current-step' : ''}`}
                href={FootprintIconPng}
                x="-12"
                y="-12"
                width="30"
                height="30"
                style={{
                  filter: 'brightness(0) saturate(100%) invert(50%) sepia(7%) saturate(2490%) hue-rotate(354deg) brightness(100%) contrast(90%)',
                  opacity: isCurrent || isHovered ? 1 : 0.7,
                }}
              />
              <text
                x="0"
                y="50"
                fill="black"
                textAnchor="middle"
                dominantBaseline="middle"
                transform={`rotate(${-angle - 90})`}
                className={`year-text textMobile2 ${isCurrent ? 'current-year' : ''}`}
                style={{
                  fontWeight: isCurrent || isHovered ? 'bold' : 'normal',
                }}
              >
                {step.year}
              </text>
            </g>
          );
        })}
      </svg>
      <Box className="history-content" sx={{ height: '300px', overflow: 'hidden' }}>
        <Box className="history-text" sx={{ height: '100%', overflowY: 'auto' }}>
          <h3 className="history-title">
            {steps.find(s => s.id === currentStep)?.text}
          </h3>
          <p style={{ fontWeight: 500, fontSize: 17 }}>
            {steps.find(s => s.id === currentStep)?.details.en}
          </p>
        </Box>
      </Box>
</div>

  );
}

export default SectionHistory;