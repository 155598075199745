
import React from "react";
import  { useState } from 'react';
import { CardActionArea } from '@mui/material';

// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CustomInput,
} from "reactstrap";

import remarkable from "assets/img/img_remarkable.jpeg"
import toggle from "assets/img/toggler.svg"
import "react-alice-carousel/lib/alice-carousel.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import img1 from 'assets/img/6.jpg'
// import img2 from 'assets/img/7.jpg'
// import img3 from 'assets/img/8.jpg'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
// import {CardContent} from '@mui/material/CardContent';
import img1 from "assets/img/V-cam-01 - Jour.png"
import img2 from "assets/img/V-cam-03 - Jour-fotor-20240922112858.png"
import img3 from "assets/img/V-cam2 - Jour.png"
import img4 from "assets/img/Villa-cam-01.jpeg"
import img5 from "assets/img/Villa-cam-03.jpeg"
import img6 from "assets/img/Villa-cam2.jpeg"
import Divider from '@mui/material/Divider';



const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

function SectionCarouselPictures() {

  const [selectedImage, setSelectedImage] = useState(img5); // default image
  const [fade, setFade] = useState(false);

  const handleImageClick = (image) => {
    setFade(true); // Start the fade-out effect

    setTimeout(() => {
      setSelectedImage(image); // Change the image after fade-out
      setFade(false); // Start the fade-in effect
    }, 200); // Match this timeout to the duration of the fade-out effect
  };

  

//   const items = [
//     <div className="item" data-value="1">1</div>,
//     <div className="item" data-value="2">2</div>,
//     <div className="item" data-value="3">3</div>,
//     <div className="item" data-value="4">4</div>,
//     <div className="item" data-value="5">5</div>,
// ];


 
  return (
    <>
       <div
       id='gallery_section'
      style={{
      
     
        height: '100%', // Makes the div cover the full viewport height
        width: '100%', // Makes the div cover the full viewport width
        position: 'relative', // Allows for positioning of child elements
      }}
    >
       <div 
      style ={{marginBottom: "1rem"}}
    >

<img 
          src={selectedImage} 
          alt="Selected"  
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            opacity: fade ? 0 : 1,
            transition: 'opacity 0.2s ease-in-out',
          }}
        />
      </div>

<Carousel  itemClass="carouselItem" responsive={responsive}>
<div> 
            <Card sx={{ maxWidth: 250 }}>
      <CardActionArea onClick={() => handleImageClick(img5)}>
        <CardMedia
          component="img"
          height="150px"
          image={img5}
          width="250px"
          alt="green iguana"
        />
       
      </CardActionArea>
    
    </Card></div>
          <div> 
            <Card sx={{ maxWidth: 250 }}>
      <CardActionArea onClick={() => handleImageClick(img1)}>
        <CardMedia
          component="img"
          height="150px"
          image={img1}
          width="250px"
        />
       
      </CardActionArea>
    
    </Card></div>

    <div> 
            <Card sx={{ maxWidth: 250 }}>
      <CardActionArea onClick={() => handleImageClick(img2)}>
        <CardMedia
          component="img"
          height="150px"
          image={img2}
          width="250px"
        />
       
      </CardActionArea>
    
    </Card></div>

    <div> 
            <Card sx={{ maxWidth: 250 }}>
      <CardActionArea onClick={() => handleImageClick(img3)}>
        <CardMedia
          component="img"
          height="150px"
          image={img3}
          width="250px"
        />
       
      </CardActionArea>
    
    </Card></div>


    <div> 
            <Card sx={{ maxWidth: 250 }}>
      <CardActionArea onClick={() => handleImageClick(img4)}>
        <CardMedia
          component="img"
          height="150px"
          image={img4}
          width="250px"
        />
       
      </CardActionArea>
    
    </Card></div>

   

    <div> 
            <Card sx={{ maxWidth: 250 }}>
      <CardActionArea onClick={() => handleImageClick(img6)}>
        <CardMedia
          component="img"
          height="150px"
          image={img6}
          width="250px"
        />
       
      </CardActionArea>
    
    </Card></div>

          



        </Carousel>

      <Divider></Divider>

  
    </div>
    </>
  );
}

export default SectionCarouselPictures;
