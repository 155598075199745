import React, { useState } from 'react';
import toggle from "assets/img/toggler-gold.svg";

function ToggleText({ title, content, color }) {
    const [isRotated, setIsRotated] = useState(false);

    const handleToggle = () => {
        setIsRotated(!isRotated);
    };

    return (
        <>
            <button 
                className="astext" 
                onClick={handleToggle}
                style={{ display: 'flex', alignItems: 'center', background: 'none', border: 'none', padding: 0 }}>
                <p style={{ color: color, fontWeight: 'bold', margin: 0, textAlign: 'left', fontSize: '14px', marginTop: "0.5rem"}}>{title}</p>
                <img 
                    src={toggle} 
                    alt="Toggle Icon" 
                    style={{ 
                        marginTop: '5px', 
                        marginLeft: '10px', 
                        height: '13px', 
                        width: '13px',
                        transition: 'transform 0.3s ease',
                        transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)' 
                    }} 
                />
            </button>

            <div
                style={{
                    fontWeight: 'bolder',
                    fontSize:'11px',
                    color: color,
                    marginTop: '5px',
                    width: '9rem',
                    opacity: isRotated ? 1 : 0,
                    maxHeight: isRotated ? '20rem' : '0',
                    overflow: 'hidden',
                    transition: 'opacity 0.3s ease-in-out, max-height 0.3s ease-in-out',
                }}
            >
                {content}
            </div>
        </>
    );
}

export default ToggleText;
