
import React from "react";
import  { useState } from 'react';

// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CustomInput,
} from "reactstrap";

import remarkable from "assets/img/img_remarkable.jpeg"
import specificImage from "assets/img/9-fotor-20240922113253.png"


function SectionSpecificImage() {

    const [isRotated, setIsRotated] = useState(false);

    // Handle click to toggle rotation
    const handleToggle = () => {
      setIsRotated(!isRotated); // Toggle the state
    };
 
  return (
    <>
       <div className="test"
      style={{
        backgroundImage: `url(${specificImage})`,
        backgroundSize: 'cover', // Ensures the image covers the whole div
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat', // Prevents repeating the image
        height: '100vh', // Makes the div cover the full viewport height
        width: '100%', // Makes the div cover the full viewport width
        position: 'relative', // Allows for positioning of child elements
      }}
    >
      

     

    </div>
    </>
  );
}

export default SectionSpecificImage;
