import React from 'react';
import { Card, CardContent, CardActions, Typography, Grid, useMediaQuery, Container, Box, Divider, Link } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import WikiIcon from '@mui/icons-material/Language';

import calcalist from 'assets/img/calcalist_logo.jpeg';
import tete from 'assets/img/tete.jpeg';
import news1 from 'assets/img/news1.jpeg';

const iconFilter = 'brightness(0) saturate(100%) invert(38%) sepia(12%) saturate(257%) hue-rotate(353deg) brightness(95%) contrast(89%)';

const cardData = [
  {
    icon: <NewspaperIcon sx={{ fontSize: 60 }} />,
    title: "News Articles",
    content: "Explore recent news and articles about Villa Sherover. Stay updated with the latest information and developments.",
    links: [
      { href: "https://m.calcalist.co.il/Article.aspx?guid=3619147", imgSrc: calcalist, altText: "Calcalist" },
      { href: "https://michaelarch.wordpress.com/2016/05/28/סיבוב-בוילה-שרובר-בירושלים/", imgSrc: tete, altText: "Michael Arch's article" },
      { href: "https://www.news1.co.il/Archive/0024-D-106919-00.html", imgSrc: news1, altText: "News1" }
    ]
  },
  {
    icon: <WikiIcon sx={{ fontSize: 60 }} />,
    title: "Wikipedia",
    content: "Dive into the rich history and background of Villa Sherover through its comprehensive Wikipedia page.",
    links: [{ href: "https://he.wikipedia.org/wiki/וילה_שרובר", text: "Read on Wikipedia" }]
  },
  {
    icon: <AutoStoriesIcon sx={{ fontSize: 60 }} />,
    title: "National Library",
    content: "Access archival materials and historical documents related to Villa Sherover from the National Library of Israel.",
    links: [{ href: "https://www.nli.org.il/en/archives/NNL_ARCHIVE_AL990048818060205171/NLI", text: "Visit National Library" }]
  },
  {
    icon: <FolderCopyIcon sx={{ fontSize: 60 }} />,
    title: "Additional Resources",
    content: "Find more resources, documents, and information about Villa Sherover and its significance.",
    links: [{ href: "#", text: "Explore Resources" }]
  }
];

const DocumentationCard = ({ icon, title, content, links }) => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardContent sx={{ flexGrow: 1 }}>
      <Box sx={{ 
        '& > svg': { 
          filter: iconFilter,
          mb: 2
        }
      }}>
        {icon}
      </Box>
      <Typography gutterBottom variant="h5" component="div">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        {content}
      </Typography>
    </CardContent>
    <CardActions sx={{ flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
      {links.map((link, index) => (
        link.imgSrc ? (
          <Link
            key={index}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img 
              src={link.imgSrc} 
              alt={link.altText} 
              style={{ 
                width: 40, 
                height: 40, 
                objectFit: 'contain',
                borderRadius: '50%',
              }} 
            />
          </Link>
        ) : (
          <Typography 
            key={index}
            component="a" 
            href={link.href} 
            target="_blank" 
            rel="noopener noreferrer"
            sx={{
              color: '#956B35',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {link.text}
          </Typography>
        )
      ))}
    </CardActions>
  </Card>
);

const ResponsiveHeadingH2 = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const ResponsiveHeadingH1 = styled(Typography)(({ theme }) => ({
  fontSize: '4rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    fontSize: '3rem',
  },
}));

const ResponsiveParagraph = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const InteractiveDocumentationSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box 
      id="documentation_section" 
      sx={{ 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
        py: 8, // Vertical padding
      }}
    >
      <Container maxWidth="lg" style={{marginBottom: '3rem'}}>
        <Box mb={6}>
          <h2 className="responsive-heading-h2">The</h2>
          <h1 className="responsive-heading-h1">DOCUMENTATION</h1>
          <ResponsiveParagraph>
            Explore the rich history and significance of Villa Sherover through various resources. 
            From news articles to historical archives, discover the story behind this iconic landmark.
            Immerse yourself in the fascinating details and learn about the impact of this architectural gem.
          </ResponsiveParagraph>
        </Box>
        
        <Grid container spacing={4}>
          {cardData.map((card, index) => (
            <Grid item xs={12} sm={isMobile ? 6 : 3} key={index}>
              <DocumentationCard {...card} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default InteractiveDocumentationSection;