import React, { useState, useRef, useEffect } from "react";
import toggle from "assets/img/toggler-gold.svg";
import { Container } from "reactstrap";
import Grid from '@mui/material/Grid';
import { ThemeProvider, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import map from 'assets/img/map_dt_2.svg'
import megamap from "assets/img/megamap.png"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { makeStyles} from '@mui/styles';
import shop from 'assets/img/shop.jpeg'
import culture from 'assets/img/culture.jpeg'
import hotels from 'assets/img/hotels.jpeg'
import green from 'assets/img/green.jpeg'
import ToggleText from "components/Button/ToggleText";
import { Stack } from "@mui/material";
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import StarIcon from '@mui/icons-material/Star';
import walkicon from 'assets/img/walk.svg'
import SvgIcon from '@mui/material/SvgIcon';
import Icon from '@material-ui/core/Icon';
import drive from 'assets/img/drive.svg'


// Change images thumbnails and main image
// Hero section mobile image


function SectionNeighborhood() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    
  };

  const style = {
    py: 0,
    width: '100%',
    maxWidth: 360,
    borderRadius: 2,
   
    borderColor: 'divider',
    backgroundColor: 'background.paper',
  };

  const useStyles = makeStyles({
    imageIcon: {
      height: '100%'
    },
    iconRoot: {
      textAlign: 'center'
    },
    tabs: {
  
      "& .MuiTabs-indicator": {
        backgroundColor: "#987F67",
        height: 3,
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#987F67'
      }
    }
  })
  
  
  const classes = useStyles();
  


  return (
    <>
       

      <div
      id='neighborhood_section'
        className="test"
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
          <Grid container spacing={0} className="responsive-grid">
        <Grid  xs={12} md={6}>
          <Container className="responsive-container">
          <h2 className="responsive-heading-h2">The</h2>
              <h1 className="responsive-heading-h1">NEIGHBORHOOD</h1>

              <p className="responsive-paragraph">
            Located in Talbiyeh, the Villa Sherover is at the heart of the city's most prestigious neighborhood. <br></br>
            From Golda Meir to Yitzhak Rabin, many of Israel's contemporary historical figures have stayed at the Villa Sherover.<br></br>
            </p>

            <br></br>

            <Grid  xs={12} md={6}>

            <img 
  className="mobile-only" 
  style={{ marginLeft: '0px', maxWidth: '90%' }} 
  src={megamap} 
  alt="Map" 
/>

</Grid>

            <Tabs
             className={classes.tabs}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#987F67",
            
          }
        }}
      
        
        value={value}
        aria-label="Tabs where each tab needs to be selected manually"
      >
        <Tab label="Explore" />
        <Tab label="Distances" />
      </Tabs>

      {value === 0 && (
        <div className="toggle-text">
          {/* Grid content for the first tab */}
          <h3 style={{color: "#987F67", fontWeight: 'bold', fontSize: "18px", marginBottom: '1rem'}}> What's around</h3>
          <Grid container  columnSpacing={{ xs: 1, sm: 2, md: -8 }} rowSpacing={3} className="icon-grid">
            <Grid item xs={6} md={3}>
                <Stack style={{marginLeft: '0rem'}}> 
                  <img height='133' style={{borderRadius: '1rem'}} src={shop}></img>
                  <ToggleText title={"SHOPPING & DINING"} color={'black'} content={<ul className="custom-list" style={{ color: "black", marginTop: '1rem', marginBottom: '0' ,      paddingLeft: '10px', 
}}>
                <li>The Regence at King David Hotel</li>
                <li>Mamila Promenade</li>
                <li>The First Station</li>
                <li>Ben Yehudah st. (The Midrechov)</li>
                <li>Machaneh Yehudah Market (The Shuk)</li>
                <li>Emek Refaim St.</li>
                <li>Angelica</li>
                <li>Mamila Roof Top</li>
                <li>O2 at Inbal Hotel</li>

              </ul>}></ToggleText>
                </Stack>
            </Grid>

            <Grid item xs={6} md={3}>
                <Stack style={{marginLeft: '0rem'}}> 
                  <img height='133px' style={{borderRadius: '1rem'}} src={culture}></img>
                  <ToggleText title={"CULTURE & WELLNESS"} color={'black'} content={<ul className="custom-list" style={{ color: "black", marginTop: '1rem', marginBottom: '0' ,      paddingLeft: '10px', 
}}>
                <li>Mea Shearim</li>
                <li>Mir Yeshiva</li>
                <li>Museum of Tolerance</li>
                <li>The Jerusalem Theater</li>
                <li>The Israel Museum</li>
                <li>National Library of Israel</li>
                <li>Jerusalem Music Centre</li>
                <li>Cinémathèque – Jerusalem</li>
                <li>Jerusalem Symphony Orchestra</li>

                  </ul>}></ToggleText>
                </Stack>
            </Grid>

            <Grid item xs={6} md={3}>
            <Stack style={{marginLeft: '0rem'}}> 
                  <img height='133' style={{borderRadius: '1rem'}} src={hotels}></img>
                  <ToggleText title={"HOTELS"} color={'black'} content={<ul className="custom-list" style={{ color: "black", marginTop: '1rem', marginBottom: '0' ,      paddingLeft: '10px', 
}}>
                <li>Waldorf Astoria</li>
                <li>King David Hotel</li>
                <li>Inbal Hotel</li>
                <li>Orient Hotel</li>
                <li>The Israel Museum</li>
                <li>Mamila Hotel</li>
                <li>The David Citadel Hotel</li>

                  </ul>}></ToggleText>
                </Stack>
            </Grid>

            <Grid item xs={6} md={3}>
            <Stack style={{marginLeft: '0rem'}}> 
                  <img height='133' style={{borderRadius: '1rem'}} src={green}></img>
                  <ToggleText title={"GREEN SPACES"} color={'black'} content={<ul className="custom-list" style={{ color: "black", marginTop: '1rem', marginBottom: '0' ,      paddingLeft: '10px', 
}}>
                <li>Rose Garden</li>
                <li>Yemin Moshe</li>
                <li>Bloomfeild Park</li>
                <li>Liberty Bell Park</li>
                <li>Sacher Park</li>
           

                  </ul>}></ToggleText>
                </Stack>
            </Grid>
            
          </Grid>
        </div>
      )}

{value === 1 && (
        <div >
          {/* Grid content for the first tab */}
          <List sx={style}>
        <ListItem>
          <ListItemText primary="Jerusalem Theater" />
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={walkicon} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span>1 Min.</span>
            </div>
          </ListItemIcon>
          
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="Rose Garden" />
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={walkicon} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span>4 Min.</span>
            </div>
          </ListItemIcon>
          
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="Hovevei Zion Synagogue" />
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={walkicon} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span>5 Min.</span>
            </div>
          </ListItemIcon>
          
        </ListItem>


        <Divider component="li" />
        <ListItem>
          <ListItemText primary="Inbal Hotel" />
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={walkicon} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span style={{ marginRight: '0.3rem' }}>10 Min.</span>
            </div>
          </ListItemIcon>
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={drive} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span >4 Min.</span>
            </div>
          </ListItemIcon>
          
        </ListItem>
        <Divider component="li" />
        
        <ListItem>
          <ListItemText primary="Orient Hotel" />
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={walkicon} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span style={{ marginRight: '0.3rem' }}>9 Min.</span>
            </div>
          </ListItemIcon>
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={drive} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span >3 Min.</span>
            </div>
          </ListItemIcon>
          
        </ListItem>
        <Divider component="li" />

        <ListItem>
          <ListItemText primary="King David Hotel" />
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={walkicon} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span style={{ marginRight: '0.3rem' }}>15 Min.</span>
            </div>
          </ListItemIcon>
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={drive} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span >4 Min.</span>
            </div>
          </ListItemIcon>
          
        </ListItem>

        <Divider component="li" />

        <ListItem>
          <ListItemText primary="Mamilla Promenade" />
         
            
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={drive} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span >10 Min.</span>
            </div>
          </ListItemIcon>
          
        </ListItem>
        <Divider component="li" />


        <ListItem>
          <ListItemText primary="King David Hotel" />
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={walkicon} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span style={{ marginRight: '0.3rem' }}>32 Min.</span>
            </div>
          </ListItemIcon>
          <ListItemIcon>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={drive} alt="Walk Icon" style={{ marginRight: '0.1rem' }} />
              <span >12 Min.</span>
            </div>
          </ListItemIcon>
          
        </ListItem>


         </List>
          


        </div>
      )}



          </Container>



        </Grid>
        <Grid  xs={12} md={6}>

        <img width='500px' style={{marginLeft: '50px'}} src={megamap} className="responsive-image" alt="Map" />

        </Grid>
       
        
      </Grid>
      <Divider sx={{ mt: 8, borderColor: 'rgba(0, 0, 0, 0.2)' }} />

      
      </div>
      

    </>
  );
}

export default SectionNeighborhood;
