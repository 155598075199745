
import React from "react";

// reactstrap components

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

// index sections

import SectionCarouselPictures from "./index-sections/SectionCarouselPictures";
import SectionHistory from "./index-sections/SectionHistory";
import SectionSpecificImage from "./index-sections/SectionSpecificImage";

import SectionPlans from "./index-sections/SectionPlans";
import SectionNeighborhood from "./index-sections/SectionNeighborhood";
import SectionContactUs from "./index-sections/SectionContactUs";
import SectionDocumentation from "./index-sections/SectionDocumentation";

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
     <IndexNavbar></IndexNavbar>
      <IndexHeader />
      <div className="main">
        <SectionHistory></SectionHistory>
        <SectionSpecificImage></SectionSpecificImage>
        <SectionCarouselPictures></SectionCarouselPictures>
        <SectionPlans></SectionPlans>
        <SectionNeighborhood></SectionNeighborhood>
        <SectionDocumentation></SectionDocumentation>

        {/* <SectionSpecificImage></SectionSpecificImage>  */}
        <SectionContactUs></SectionContactUs>
       
        

        {/* <SectionButtons />
        {/* <SectionNavbars />
        <SectionNavigation />
        <SectionProgress />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavaScript />
        <SectionCarousel />
        <SectionNucleoIcons />
        <SectionDark />
        <SectionLogin />
        <SectionExamples />
        <SectionDownload /> */}
        <DemoFooter />
      </div>
    </>
  );
}

export default Index;
