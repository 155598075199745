import React from 'react';

const AccessibilityStatement = () => {
  const containerStyle = {
    padding: '40px',
    maxWidth: '1000px',
    margin: '0 auto',
  };

  const titleStyle = {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '20px',
  };

  const subtitleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '15px',
  };

  const textStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '20px',
    fontWeight: '500',
  };

  const listStyle = {
    marginLeft: '30px',
    marginBottom: '20px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Accessibility Statement</h1>
      <p style={textStyle}>
        Villa Sherover ("the Company"), places great importance on providing equal opportunity for people with disabilities to make use of its website https://villasherover.com ("the Site").
      </p>
      <p style={textStyle}>
        In accordance with the Equal Rights for Persons with Disabilities Act 1998 and the regulations that were put in place by this Act, many efforts and resources are invested by the Company in making the information and services available on the site accessible to those with disabilities in order to enable them to browse the site with the same level of efficiency and enjoyment as all surfers. The accessibility of the site is optimized for the most popular browsers, including Chrome, Firefox, Safari Opera.
      </p>

      <h2 style={subtitleStyle}>Site accessibility information</h2>
      <p style={textStyle}>
        This site complies with the Equal Rights Regulations for Persons with Disabilities (Service Accessibility Adjustments), 2013. The accessibility adjustments were made in compliance with the Israeli standard (IS 5568) for accessibility of Internet content at AA level and the international WCAG2.0 document. Accessibility on the site is performed, inter alia, through an accessibility button. Clicking on the button causes an opening of a menu of several accessibility aids that include, inter alia:
      </p>
      <ul style={listStyle}>
        <li style={textStyle}>Customized keyboard navigation-Enabling an option to navigate using a keyboard.</li>
        <li style={textStyle}>Site adjustment for a screen reader -Site adjustment for assistive technologies such as NVDA, JAWS</li>
        <li style={textStyle}>Increasing the font of the site to 5 different sizes</li>
        <li style={textStyle}>Blocking of flashes- Stopping moving elements and blocking flashes.</li>
        <li style={textStyle}>Contrast adjustment – Change of color contrast based on a dark background</li>
        <li style={textStyle}>Contrast adjustment – Change of color contrast based on a bright background</li>
        <li style={textStyle}>Site adjustment for the colorblind</li>
        <li style={textStyle}>The changing of the font to be more readable</li>
        <li style={textStyle}>Enlargement of the cursor and changing its color to black or white</li>
        <li style={textStyle}>Increasing the display to about 200%</li>
        <li style={textStyle}>Highlighting of site links</li>
        <li style={textStyle}>Highlighting of site headlines</li>
        <li style={textStyle}>Displaying an alternative description of pictures</li>
      </ul>

      <h2 style={subtitleStyle}>Accessibility declaration</h2>
      <p style={textStyle}>
        We are continuing our efforts to improve the accessibility of the site as much as possible as part of our commitment to enable the entire population, including people with disabilities, to receive the most accessible service. Nevertheless, parts of the site may be discovered that are not yet accessible and/or whose accessibility has been compromised due to technical or other malfunctions. Should you come across a problem or malfunction regarding accessibility issues on our site, please let us know about it and we will take action to handle it as soon as possible.
      </p>

      <h2 style={subtitleStyle}>Contact us regarding accessibility issues</h2>
      <p style={textStyle}>
        You can contact us in any one of the following ways:
      </p>
      <ul style={listStyle}>
        <li style={textStyle}>Phone: 054-428-2815</li>
        <li style={textStyle}>Email: <a href="mailto:brakagary@gmail.com">brakagary@gmail.com</a></li>
      </ul>

      <p style={textStyle}>
        The accessibility declaration has updated on the day: 08.10.2024
      </p>
    </div>
  );
};

export default AccessibilityStatement;
